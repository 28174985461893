import axios from "../axios/axios-kaqadmin";
import {useBearerTokenHeaders} from "../utils/functions";

export const contractForms = {
    getContractForm,
    updateContractForm
}


function getContractForm(slug){

    return new Promise((resolve, reject) => {
        axios.get('/contracts/get/'+slug, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    });

}

function updateContractForm(slug, content){

    return new Promise((resolve, reject) => {
        axios.post('/contracts/update/'+slug, {
            'content': content
        } ,useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    });

}