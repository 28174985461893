import { userService } from '../../apis/user.service';

const state = {
}

const getters = {
    'GET_USER_INFO' () {
        const userInfo = localStorage.getItem('userInfo');
        return userInfo ? JSON.parse(userInfo) : null;
    }
}

const mutations = {
    // Updates user info in state and localstorage
    SET_USER_INFO (state, payload) {
        // Store data in localStorage
        localStorage.setItem("userInfo", JSON.stringify(payload))
    },

    'CLEAR_USER_INFO' () {
        localStorage.removeItem('userInfo');
    },

    UPDATE_USER_INFO(state, {email, phone}) {
        const userInfo = localStorage.getItem('userInfo');
        const userInfoJson =  JSON.parse(userInfo);
        userInfoJson['email'] = email;
        userInfoJson['phone'] = phone;

        localStorage.setItem("userInfo", JSON.stringify(userInfoJson))
    }

}

const actions = {
    // JWT
    login({commit, dispatch}, payload) {

        dispatch('showLoader');
        userService.login(payload.email, payload.password).then(data => {
            dispatch('hideLoader')
                    if(data.status){
                        // commit on data in the extra property
                        commit('SET_USER_INFO', data.extra)
                        window.location.href = "/"
                        return;
                    }
                    dispatch('error', {message: data.message}, { root: true });
                },
            );
    },

    logout({commit}){
        userService.logout();
        commit('CLEAR_USER_INFO');
        window.location.href = "login"
    },

    changePassword({commit, dispatch}, payload){
        dispatch('showLoader')
        userService.changePassword(payload).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }
            dispatch('success', {message: 'Password changed!', showSwal: true});
        })
    },

    updatePersonalInfo({commit, dispatch}, payload){

        dispatch('showLoader')
        userService.updatePersonalInfo(payload).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }
            commit('UPDATE_USER_INFO', payload)
            dispatch('success', {message: 'Information updated!' , showSwal: true});
        })
    }

}

export default {
    state,
    getters,
    mutations,
    actions
}

