import {unit4Service} from "../../apis/unit4.service";

const state = {
    selectedUnit4Config: null,
    selectedSystemConfig: null,
    availableYears: null,
    downloadTokens: null,
    rekSchNums: []
}

const getters = {
    'GET_SELECTED_UNIT4_CONFIG' (state) {
        return state.selectedUnit4Config;
    },
    'GET_SELECTED_SYSTEM_CONFIG' (state) {
        return state.selectedSystemConfig;
    },
    'GET_AVAILABLE_YEARS' (state) {
        return state.availableYears;
    },
    'GET_REK_SCHS' (state) {
        return state.rekSchNums;
    },
    'GET_UNIT4_DOWNLOAD_TOKENS' (state) {
        return state.downloadTokens;
    },
}
const mutations = {
    'SET_SELECTED_UNIT4_CONFIG' (state, config) {
        state.selectedUnit4Config = config
    },
    'SET_SELECTED_SYSTEM_CONFIG' (state, config) {
        state.selectedSystemConfig = config
    },
    'SET_AVAILABLE_YEARS' (state, years) {
        state.availableYears = years
    },
    'SET_REK_SCHS' (state, data) {
        state.rekSchNums = data
    },
    'SET_UNIT4_DOWNLOAD_TOKENS' (state, tokens) {
        state.downloadTokens = tokens
    },
}

const actions = {

    getSelectedUnit4Config({commit, dispatch}, type){
        dispatch('resetUnit4Config');

        dispatch('showLoader')
        unit4Service.fetchConfigs(type).then(data => {
            dispatch("hideLoader")
            if(!data.status){
                dispatch('error', {message:data.message})
                console.log(data.message)
                return;
            }

            commit('SET_SELECTED_UNIT4_CONFIG', data.extra)

        })
    },

    setUnit4Config({commit, dispatch}, formData){
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            unit4Service.setConfigurationWithExcel(formData).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                commit('SET_SELECTED_UNIT4_CONFIG', data.extra)
                dispatch('success', {message: 'Completed', showSwal: true})
                resolve()

            })
        })
    },

    attachDebiteurToProvider({commit, dispatch}, payload){

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            unit4Service.attachDebiteurToProvider(payload).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                commit('SET_SELECTED_UNIT4_CONFIG', data.extra)
                dispatch('success', {message: 'Completed', showSwal: true})
                resolve()

            })
        })
    },

    attachRekNumToConfig({commit, dispatch}, payload){

        console.log('attachRekNumToConfig payload', payload)
        dispatch('showLoader')
        unit4Service.attachRekNumToConfig(payload).then(data => {
            dispatch("hideLoader")
            console.log('data', data)
            if(!data.status){
                console.log(data.message)
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }

            commit('SET_SELECTED_UNIT4_CONFIG', data.extra)
            dispatch('success', {message: 'Completed', showSwal: true})

        }).catch((e) => {
            console.log('error: ',e)
        })
    },

    resetUnit4Config({commit}){
        commit('SET_SELECTED_UNIT4_CONFIG', null)
    },

//    System config
    getSelectedSystemConfig({commit, dispatch}, payload){

        dispatch('showLoader')
        unit4Service.getConfigByConfigType(payload).then(data => {
            dispatch("hideLoader")
            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                console.log(data.message)
                return;
            }

            commit('SET_SELECTED_SYSTEM_CONFIG', data.extra)

        })
    },

//    available years
    getAvailableYears({commit, dispatch}){

        dispatch('showLoader')
        unit4Service.getAvailableYears().then(data => {
            dispatch("hideLoader")
            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                console.log(data.message)
                return;
            }

            commit('SET_AVAILABLE_YEARS', data.extra)

        })
    },

//    available years
    getRekSchBySheet({commit, dispatch}, sheet){

        dispatch('showLoader')
        unit4Service.getRekSchBySheet(sheet).then(data => {
            dispatch("hideLoader")
            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                console.log(data.message)
                return;
            }

            commit('SET_REK_SCHS', data.extra)

        })
    },

//    Generate verkoop book
    generateVerkoopBook({commit, dispatch}, payload){

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            unit4Service.generateVerkoopBook(payload).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                dispatch('success', {message:data.message, showSwal: true})
                resolve(data.extra)

            })
        })
    },

//    Generate verkoop book
    generateInkoopBook({commit, dispatch}, payload){


        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            unit4Service.generateInkoopBook(payload).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                dispatch('success', {message:data.message, showSwal: true})
                resolve(data.extra)

            })
        })
    },

    //    Generate Memorial sheet
    generateMemorialSheet({commit, dispatch}, payload){

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            unit4Service.generateMemorialSheet(payload).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                dispatch('success', {message:data.message, showSwal: true})
                resolve(data.extra)

            })
        })
    },

//    get download tokens
    getUni4DownloadTokens({commit, dispatch}, type){

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            unit4Service.getUni4DownloadTokens(type).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                commit('SET_UNIT4_DOWNLOAD_TOKENS', data.extra)

            })
        })
    },


}

export default {
    state,
    getters,
    actions,
    mutations
}