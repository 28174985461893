import axios from "../axios/axios-kaqadmin";
import {useBearerTokenHeaders} from "../utils/functions";

export const faqService = {
    getFaqs,
    createFaq,
    removeFaq,
    updateFaq

}

function getFaqs(){
    return new Promise((resolve, reject) => {
        axios.get('/faqs', useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    })
}

function createFaq(formData){
    return new Promise((resolve, reject) => {
        axios.post('/faqs/create', formData, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function removeFaq(id){
    return new Promise((resolve, reject) => {
        axios.get('/faqs/remove/'+ id, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function updateFaq(id, formData){
    return new Promise((resolve, reject) => {
        axios.post('/faqs/update/'+ id, formData, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}