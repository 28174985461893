import {financialAdministrationService} from "../../apis/financial.administration.service";

const state = {
    providerConfigFields: null,
    externalDataConfigFields: null,
    internalDataConfigFields: null,
    selectedExternalDriverFiles: [],
    financialData: null,
}

const getters = {
    'GET_PROVIDER_CONFIG_FIELDS'(state) {
        return state.providerConfigFields;
    },
    'GET_EXTERNAL_DATA_CONFIG_FIELDS'(state) {
        return state.externalDataConfigFields;
    },
    'GET_INTERNAL_DATA_CONFIG_FIELDS'(state) {
        return state.internalDataConfigFields;
    },
    'GET_SELECTED_DRIVER_EXT_FILES'(state) {
        return state.selectedExternalDriverFiles
    },
    'GET_FINANCIAL_DATA'(state) {
        return state.financialData;
    }
}

const mutations = {

    'SET_PROVIDER_CONFIG_FIELDS'(state, configFields) {
        state.providerConfigFields = configFields;
    },
    'SET_EXTERNAL_DATA_CONFIG_FIELDS'(state, configFields) {
        state.externalDataConfigFields = configFields;
    },
    'SET_INTERNAL_DATA_CONFIG_FIELDS'(state, configFields) {
        state.internalDataConfigFields = configFields;
    },
    'SET_SELECTED_DRIVER_EXT_FILES'(state, files = []) {
        state.selectedExternalDriverFiles = files
    },
    'SET_FINANCIAL_DATA'(state, data) {
        state.financialData = data
    }
}

const actions = {

    fetchProviders({commit, dispatch}) {
        dispatch('showLoader')
        financialAdministrationService.getProviders().then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message})
                return;
            }
            commit('SET_PROVIDERS', data.extra);
        })
    },

    addProvider({commit, dispatch}, payload) {
        dispatch('showLoader')
        financialAdministrationService.addProvider(payload).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message})
                return;
            }
            commit('ADD_PROVIDER', data.extra);
            dispatch('success', {message: data.message})
        })
    },

    removeProvider({commit, dispatch}, id) {
        dispatch('showLoader')
        financialAdministrationService.removeProvider(id).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message})
                return;
            }
            commit('REMOVE_PROVIDER', id);
            dispatch('success', {message: 'REMOVED!'})
        })
    },

    updateProvider({commit, dispatch}, {id, name}) {
        dispatch('showLoader')
        financialAdministrationService.updateProvider(id, {'name': name}).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message})
                return;
            }
            commit('SET_PROVIDERS', data.extra);
        })
    },

    setProviderConfigFields({commit, dispatch}, formData) {
        dispatch('showLoader')
        financialAdministrationService.setProviderConfigFields(formData).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                return;
            }
            dispatch('success', {message: 'Fields updated', showSwal: true})
        })
    },

    getProviderConfigFields({commit, dispatch}, providerId) {
        dispatch('showLoader')
        financialAdministrationService.getProviderConfigFields(providerId).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message})
                return;
            }
            commit('SET_PROVIDER_CONFIG_FIELDS', data.extra);
        })
    },


    setExternalDataConfigFields({commit, dispatch}, formData) {
        dispatch('showLoader')
        financialAdministrationService.setExternalDataConfigFields(formData).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                return;
            }
            dispatch('success', {message: 'Config fields updated', showSwal: true})
        })
    },

    getExternalDataConfigFields({commit, dispatch}) {
        dispatch('showLoader')
        financialAdministrationService.getExternalDataConfigFields().then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message})
                return;
            }
            commit('SET_EXTERNAL_DATA_CONFIG_FIELDS', data.extra);
        })
    },

    setInternalDataConfigFields({commit, dispatch}, {type, formData}) {
        dispatch('showLoader')
        financialAdministrationService.setInternalDataConfigFields(type, formData).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                return;
            }
            dispatch('success', {message: 'Config fields updated', showSwal: true})
        })
    },

    getInternalDataConfigFields({commit, dispatch}, type) {
        dispatch('showLoader')
        financialAdministrationService.getInternalDataConfigFields(type).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message})
                return;
            }
            commit('SET_INTERNAL_DATA_CONFIG_FIELDS', data.extra);
        })
    },


    fetchUploadedExternalFiles({commit, dispatch}, {userId, request, showLoader = true, url}) {

        return new Promise((resolve, reject) => {
            if (showLoader) {
                dispatch('showLoader')
            }

            financialAdministrationService.fetchUploadedExternalFiles(userId, request, url).then(data => {
                dispatch('hideLoader')

                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject()
                    return;
                }

                commit('SET_SELECTED_DRIVER_EXT_FILES', data.extra.data)
                resolve(data.extra)
            })
        })
    },
    fetchAllUploadedExternalFiles({commit, dispatch}, {userId, request, url, showLoader = true}) {
        return new Promise((resolve, reject) => {
            if (showLoader) {
                dispatch('showLoader')
            }
            financialAdministrationService.fetchAllUploadedExternalFiles(userId, request, url).then(data => {
                dispatch('hideLoader')

                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject()
                    return;
                }

                commit('SET_SELECTED_DRIVER_EXT_FILES', data.extra.data)
                resolve(data.extra)
            })
        })
    },
    searchReceiptData({commit, dispatch},{search, url, showLoader = true}) {
        return new Promise((resolve, reject) => {
            if (showLoader) {
                dispatch('showLoader')
            }
            financialAdministrationService.searchReceiptData({search}, url).then(data => {
                dispatch('hideLoader')

                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject()
                    return;
                }

                commit('SET_SELECTED_DRIVER_EXT_FILES', data.extra.data)
                resolve(data.extra)
            })
        })
    },

    searchReceiptByInvoiceNo({commit, dispatch}, {userId, request, showLoader = true}) {
        return new Promise((resolve, reject) => {
            if (showLoader) {
                dispatch('showLoader')
            }
            financialAdministrationService.searchReceiptByInvoiceNo(request).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject()
                    return;
                }

                commit('SET_SELECTED_DRIVER_EXT_FILES', data.extra.data)
                resolve(data.extra)
            })
        })
    },

    updateExternalFileStatus({commit, dispatch}, {fileId, request}) {
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            financialAdministrationService.updateExternalFileStatus(fileId, request).then(data => {
                dispatch('hideLoader')

                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject()
                    return;
                }

                commit('SET_SELECTED_DRIVER_EXT_FILES', data.extra)
                dispatch('success', {message: 'Done!', showSwal: true});
                resolve()

            })
        })
    },

    saveDataOnExternalReceipts({commit, dispatch}, {fileId, request}) {

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            financialAdministrationService.saveDataOnExternalReceipts(fileId, request).then(data => {
                dispatch('hideLoader')

                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject()
                    return;
                }

                commit('SET_SELECTED_DRIVER_EXT_FILES', data.extra)
                dispatch('success', {message: 'Done!', showSwal: true});
                resolve();

            })
        })
    },

    approveExternalFileStatus({commit, dispatch}, fileId) {

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            financialAdministrationService.approveExternalReceipts(fileId).then(data => {
                dispatch('hideLoader')

                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject();
                    return;
                }

                commit('SET_SELECTED_DRIVER_EXT_FILES', data.extra)
                dispatch('success', {message: 'Done!', showSwal: true});
                resolve();

            })
        })
    },


}

export default {
    state,
    getters,
    actions,
    mutations
}
