import { faqService} from "../../apis/faq.service";

const state = {
    faqs: []
}

const getters = {
    'GET_FAQS' (state) {
        return state.faqs
    }
}

const mutations = {
    'SET_FAQS' (state, faqs){
        state.faqs = faqs;
    }
}

const actions = {

    getFaqs({commit, dispatch}){
        dispatch('showLoader')
        faqService.getFaqs().then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message})
                return;
            }
            commit('SET_FAQS', data.extra);
        })
    },

    createFaq({commit, dispatch}, formData){
        dispatch('showLoader')
        faqService.createFaq(formData).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message})
                return;
            }
            commit('SET_FAQS', data.extra);
            dispatch('success', {message: 'ADDED', showSwal: true})
        })
    },

    updateFaq({commit, dispatch}, {id, payload}){
        dispatch('showLoader')
        faqService.updateFaq(id, payload).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message})
                return;
            }
            commit('SET_FAQS', data.extra);
        })
    },

    removeFaq({commit, dispatch}, id){
        dispatch('showLoader')
        faqService.removeFaq(id).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message})
                return;
            }
            commit('SET_FAQS', data.extra);
        })
    }


}

export default {
    state,
    getters,
    actions,
    mutations
}