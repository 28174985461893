import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth";
import notification from "./modules/notification";
import loader from "./modules/loader";
import driverEnrolment from './modules/driver.enrolment'
import rentals from "./modules/rentals";
import breadcrumb from "./modules/breadcrumb";
import contractForm from "./modules/contract.form"
import financialAdministration from "./modules/financial.administration"
import faq from "./modules/faq"
import drviers from "./modules/drivers"
import providers from "./modules/providers";
import unit4 from "./modules/unit4";
import financialSheet from  './modules/financial.sheet';
import administration from  './modules/administration';

Vue.use(Vuex)

const store = new Vuex.Store({

  modules: {
    auth,
    notification,
    loader,
    driverEnrolment,
    rentals,
    breadcrumb,
    contractForm,
    financialAdministration,
    faq,
    drviers,
    providers,
    unit4,
    financialSheet,
    administration

  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store

