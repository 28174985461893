import {useBearerTokenHeaders} from "../utils/functions";
import axios from "../axios/axios-kaqadmin";

export const rentalsService = {
    fetchCars,
    addNewCar,
    updateCar,
    removeCar,
    fetchCarDetail,
    addControls,
    fetchControls,
    removeControl,
    updateConfigurations,
    getConfigurations,
    submitRentRequest,
    calculateRentalCost,
    completeDepartureControl,
    completeArrivalControl,
    cancelRentRequest,
    fetchRentalDetail,
    fetchScheduledRentalList,
    submitDriverDepartureControl,
    submitDriverArrivalControl,
    fetchBookedDates,
    downloadRentalData
}


function fetchCars(){

    return new Promise((resolve, reject) => {
        axios.get('/rentals/get-cars', useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
       }).catch(err => resolve({status: false, message: err}));
    })

}

function downloadRentalData(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/rentals/download-rental-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function fetchCarDetail(id){

    return new Promise((resolve, reject) => {
        axios.get('/rentals/get-car/' + id, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function addNewCar(formData){

    return new Promise((resolve, reject) => {
        axios.post('/rentals/add-car', formData, useBearerTokenHeaders(true) )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function updateCar({carId, formData}){

    return new Promise((resolve, reject) => {
        axios.post('/rentals/update-car/'+carId, formData, useBearerTokenHeaders(true) )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function removeCar(id){
    return new Promise((resolve, reject) => {
        axios.get('/rentals/remove-car/' + id, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function addControls(formData){
    return new Promise((resolve, reject) => {
        axios.post('rentals/create-controls',formData, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function fetchControls(){
    return new Promise((resolve, reject) => {
        axios.get('rentals/fetch-controls', useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function removeControl(id){
    return new Promise((resolve, reject) => {
        axios.get('rentals/remove-control/' + id, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function fetchRentalDetail(id){
    return new Promise((resolve, reject) => {
        axios.get('rentals/get-rental-detail/' + id, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function updateConfigurations(payload){
    return new Promise((resolve, reject) => {
        axios.post('rentals/update-configurations', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getConfigurations(){
    return new Promise((resolve, reject) => {
        axios.get('rentals/get-configurations', useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function submitRentRequest(payload){
    return new Promise((resolve, reject) => {
        axios.post('rentals/assign-car-to-driver', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function cancelRentRequest(rentalId){
    return new Promise((resolve, reject) => {
        axios.get('rentals/cancel-car-from-driver/' + rentalId, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function calculateRentalCost(payload){
    return new Promise((resolve, reject) => {
        axios.post('rentals/get-rental-cost', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}
// This is administrative departure control .... NOT DRIVER DEPARTURE CONTROL
function completeDepartureControl(payload){
    return new Promise((resolve, reject) => {
        axios.post('rentals/complete-departure-control', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

// This is administrative arrival control .... NOT DRIVER ARRIVAL CONTROL
function completeArrivalControl(payload){

    let url = 'rentals/complete-arrival-control';
    if(payload['confirm'] === true){
        url = 'rentals/confirm-arrival-control'
    }
    return new Promise((resolve, reject) => {
        axios.post(url, payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function submitDriverDepartureControl(payload){
    return new Promise((resolve, reject) => {
        axios.post('rentals/complete-driver-departure-control', payload, useBearerTokenHeaders(true) )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function submitDriverArrivalControl(payload){

    let url = 'rentals/complete-driver-arrival-control';
    // if(payload['confirm'] === true){
    //     url = 'rentals/confirm-arrival-control'
    // }
    return new Promise((resolve, reject) => {
        axios.post(url, payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}




function fetchScheduledRentalList(carId){

    return new Promise((resolve, reject) => {
        axios.get('/rentals/scheduled-rentals/' + carId, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function fetchBookedDates(carId){

    return new Promise((resolve, reject) => {
        axios.get('/rentals/get-booked-dates/' + carId, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}