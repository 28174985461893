import {useBearerTokenHeaders} from "../utils/functions";
import axios from "../axios/axios-kaqadmin";

export const driverEnrolmentService = {
    fetchProspectiveDriverList,
    fetchProspectiveDriverDetail,
    generateEnrolmentFileToken,
    updateEnrolmentDocumentStatus,
    removeEnrolmentDocument,
    addNewDriverEnrolmentFile,
    addOrUpdateEnrolmentDetails,
    markAsCompleted
}

function fetchProspectiveDriverList() {

    return new Promise((resolve, reject) => {
        axios.get('/enrolment/fetch-prospective-drivers', useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
        }).catch(err => resolve({status: false, message: err}));
    })

}

function fetchProspectiveDriverDetail(id){

    return new Promise((resolve, reject) => {
        axios.get('/enrolment/fetch-prospective-drivers/' + id, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function generateEnrolmentFileToken(docID){
    ///enrolment/download-prospective-driver-document/
    return new Promise((resolve, reject) => {
        axios.post('/enrolment/generate-driver-enrolment-file-download-session', {
            document_id: docID
        }, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function updateEnrolmentDocumentStatus({docId, newStatus, comment}){

    return new Promise((resolve, reject) => {
        axios.post('/enrolment/update-prospective-driver-doc-status/'+docId+'/'+newStatus, {
            comment: comment
        }, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })


}

function removeEnrolmentDocument(docId){

    return new Promise((resolve, reject) => {

        axios.get('/enrolment/delete-prospective-driver-doc/'+docId, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
        }).catch(err => resolve({status: false, message: err}));

    });

}

function addNewDriverEnrolmentFile(userId, formData){
    return new Promise((resolve, reject) => {

        axios.post('/enrolment/upload-driver-document/'+userId, formData ,useBearerTokenHeaders(true))
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    });
}


function addOrUpdateEnrolmentDetails(userId, formData){
    return new Promise((resolve, reject) => {

        axios.post('/enrolment/add-or-update-driver-enrolment-details/'+userId, formData ,useBearerTokenHeaders(true))
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    });
}

function markAsCompleted(userId){

    return new Promise((resolve, reject) => {

        axios.get('/enrolment/mark-enrolment-as-completed/' + userId  ,useBearerTokenHeaders(true))
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    });
}
