import {useBearerTokenHeaders} from "../utils/functions";
import axios from "../axios/axios-kaqadmin";

export const providersService = {
    fetchPendingUploadedData,
    uploadProviderData,
    rejectPendingUploadedData,
    acceptPendingUploadedData
}


function fetchPendingUploadedData(){

    return new Promise((resolve, reject) => {
        axios.get('/providers/fetch-pending-provider-upload', useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function uploadProviderData(formData){
    return new Promise((resolve, reject) => {
        axios.post('/providers/upload-provider-data', formData, useBearerTokenHeaders(true) )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function rejectPendingUploadedData(campaignId){

    return new Promise((resolve, reject) => {
        axios.get('/providers/reject-pending-provider-upload/' + campaignId, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function acceptPendingUploadedData(campaignId){

    return new Promise((resolve, reject) => {
        axios.get('/providers/accept-pending-provider-upload/' + campaignId, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}
