import axios from "../axios/axios-kaqadmin";
import {useBearerTokenHeaders} from "../utils/functions";

export const financialAdministrationService = {
    getProviders,
    addProvider,
    removeProvider,
    updateProvider,
    getProviderConfigFields,
    setProviderConfigFields,
    getExternalDataConfigFields,
    setExternalDataConfigFields,
    getInternalDataConfigFields,
    setInternalDataConfigFields,
    fetchUploadedExternalFiles,
    fetchAllUploadedExternalFiles,
    searchReceiptData,
    updateExternalFileStatus,
    saveDataOnExternalReceipts,
    approveExternalReceipts,
    getFinancialData,
    updatePreferences,
    sendInvoice,
    deleteFDataItem,
    addExtraFinancialData,
    uploadExt2021Data,
    getExt2021Data,
    deleteExt2021Data,
    confirmExt2021Data,
    updateFinancialData,
    deleteFinancialData,
    approveAllReceipts,
    searchFinancialData,
    deleteQueriedFinancialRecords,
    downloadFinancialData,
    downloadReceiptData,
    searchReceiptByInvoiceNo,
    searchFinancialDataByUrl,
    getFinancialDataDownload,
    getReceiptDataDownload,
}

function getProviders() {
    return new Promise((resolve, reject) => {
        axios.get('/f-admin/providers', useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    })
}

function getFinancialDataDownload() {
    return new Promise((resolve, reject) => {
        axios.get('/f-admin/get-financial-data-download', useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    })
}

function getReceiptDataDownload() {
    return new Promise((resolve, reject) => {
        axios.get('/f-admin/get-receipt-data-download', useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    })
}

function searchFinancialInfo(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/get-financial', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}
function approveAllReceipts() {
    return new Promise((resolve) => {
        axios.post('/f-admin/approve-all-accepted-receipt', {}, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function searchFinancialData(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/get-financial-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function searchFinancialDataByUrl(url, payload) {
    return new Promise((resolve, reject) => {
        axios.post(url, payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function searchReceiptByInvoiceNo(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/get-all-driver-ext-files', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function downloadFinancialData(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/download-financial-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function downloadReceiptData(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/download-receipt-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function deleteFinancialData(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/delete-financial-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}
function deleteQueriedFinancialRecords(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/delete-queried-financial-records', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function updateFinancialData(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/update-financial-data', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function addProvider(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/add-provider', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function removeProvider(id) {
    return new Promise((resolve, reject) => {
        axios.get('/f-admin/remove-provider/' + id, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function updateProvider(id, formData) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/update-provider/' + id, formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function getProviderConfigFields(providerId) {

    if (providerId) {
        providerId = '/' + providerId;
    }
    return new Promise((resolve, reject) => {
        axios.get('/f-admin/get-provider-config-fields' + providerId, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    })
}


function setProviderConfigFields(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/set-provider-config-fields', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function getExternalDataConfigFields() {

    return new Promise((resolve, reject) => {
        axios.get('/f-admin/get-external-data-config-fields', useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    })
}


function setExternalDataConfigFields(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/set-external-data-config-fields', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function getInternalDataConfigFields(type) {

    return new Promise((resolve, reject) => {
        axios.get('/f-admin/get-internal-data-config-fields/' + type, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    })
}


function setInternalDataConfigFields(type, formData) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/set-internal-data-config-fields/' + type, formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function fetchUploadedExternalFiles(userId, formData, url = null) {
    return new Promise((resolve, reject) => {
        axios.post(url ? url : '/f-admin/get-driver-ext-files/' + userId, formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function fetchAllUploadedExternalFiles(userId, formData, url = null) {
    return new Promise((resolve, reject) => {
        axios.post(url ? url : '/f-admin/get-all-driver-ext-files', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function searchReceiptData(formData, url = null) {
    return new Promise((resolve, reject) => {
        axios.post(url ? url : '/f-admin/search-receipt-data', formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function updateExternalFileStatus(fileId, formData) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/update-ext-file-status/' + fileId, formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function saveDataOnExternalReceipts(fileId, formData) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/save-ext-file-data/' + fileId, formData, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function approveExternalReceipts(fileId) {
    return new Promise((resolve, reject) => {
        axios.get('/f-admin/final-approve-ext-file-data/' + fileId, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getFinancialData(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/get-driver-financial-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function addExtraFinancialData(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/add-extra-financial-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function updatePreferences(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/update-preferences', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function sendInvoice(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/send-invoice', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function deleteFDataItem(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/delete-f-data-item', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function uploadExt2021Data(payload) {

    return new Promise((resolve, reject) => {
        axios.post('/f-admin/upload-ext-2021-data', payload, useBearerTokenHeaders(true))
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function getExt2021Data(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/get-ext-2021-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function deleteExt2021Data(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/delete-ext-2021-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function confirmExt2021Data(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/confirm-ext-2021-data', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}
