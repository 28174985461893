import {providersService} from "../../apis/providers.service";

const state = {
    providers: [],
    uploadedData: null
}

const getters = {
    'GET_PROVIDERS' (state) {
        return state.providers;
    },
    'GET_UPLOADED_DATA' (state) {
        return state.uploadedData;
    },
}
const mutations = {
    'SET_PROVIDERS' (state, data) {
        return state.providers = data;
    },
    'ADD_PROVIDER' (state, provider) {
        state.providers.push(provider)
    },
    'REMOVE_PROVIDER' (state, id) {
        const index = state.providers.findIndex(e => e.id === id);
        state.providers.splice(index, 1);
    },
    'SET_UPLOADED_DATA' (state, data) {
        console.log(data)
        return state.uploadedData = data;
    },
}

const actions = {

    fetchPendingUploadedData({commit, dispatch}){
        dispatch('showLoader')
        providersService.fetchPendingUploadedData().then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message})
                return;
            }
            commit('SET_UPLOADED_DATA', data.extra);
        })
    },


    uploadProviderData({commit, dispatch}, formData) {

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            providersService.uploadProviderData(formData).then(data => {
                dispatch('hideLoader')

                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    reject();
                    return;
                }

                commit('SET_UPLOADED_DATA', data.extra);
                dispatch('success', {message: 'File added', showSwal: true});
                resolve()

            })
        });
    },

    rejectPendingUploadedData({commit, dispatch}, $campaignId){
        dispatch('showLoader')
        providersService.rejectPendingUploadedData($campaignId).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }
            commit('SET_UPLOADED_DATA', null);
            dispatch('success', {message:data.message, showSwal: true})
        })
    },

    acceptPendingUploadedData({commit, dispatch}, $campaignId){
        dispatch('showLoader')
        providersService.acceptPendingUploadedData($campaignId).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }
            commit('SET_UPLOADED_DATA', null);
            dispatch('success', {message:data.message, showSwal: true})
        })
    },


}

export default {
    state,
    getters,
    actions,
    mutations
}