import routes from "../../router/routes";

const state = {
    route: [],
    pageTitle: ''
}

const getters = {

    'GET_ROUTE' (state){
        return state.route
    },
    'GET_PAGE_TITLE' (state){
        return state.pageTitle
    },
}
const mutations = {
    'SET_ROUTE_PATH' (state, {to, from}) {

        if(!state.route.includes(to)){
            state.route.push(to)
        }

        if(to === '/'){
            state.route = ['/'];
        }

        console.log('route: ', state.route)

    },
    'REMOVE_LAST' (state) {
        console.log('route: ', state.route)
        state.route.pop();
    },
    'SET_PAGE_TITLE' (state, title) {
        state.route = [
            state.route[0],
            state.route[state.route.length - 1]
        ]
        console.log('route: ', state.route)
        state.pageTitle = title;
    },

}

const actions = {

    setRoutePath({commit}, {to, from}){
        commit('SET_ROUTE_PATH', {to, from})
    },
    popRoutePath({commit}){
        commit('REMOVE_LAST')
    },
    setPageTitle({commit}, title){
        commit('SET_PAGE_TITLE',title)
    }


}

export default {
    state,
    getters,
    actions,
    mutations
}