import {useBasicAuthHeaders, useBearerTokenHeaders} from "../utils/functions";
import axios from "../axios/axios-kaqadmin";

export const userService = {
    login,
    logout,
    changePassword,
    updatePersonalInfo
};

function login(email, password) {

    //// fetch from the server
    const data = {
        "grant_type": "password",
        "client_id": process.env.VUE_APP_CLIENT_ID,
        "client_secret": process.env.VUE_APP_CLIENT_SECRET,
        "username": email,
        "password": password,
        "scope": ""
    }

    return new Promise((resolve, reject) => {
        axios.post('/oauth/token', data, useBasicAuthHeaders() )
            .then(response => {
                 resolve(response.data)
        }).catch(err => resolve({status: false, message: err}));
    })

}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}


function changePassword(payload) {
    return new Promise((resolve, reject) => {
        axios.post('/settings/change-password', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function updatePersonalInfo(payload){
    return new Promise((resolve, reject) => {
        axios.post('/settings/update-personal-info', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


