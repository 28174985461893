import {contractForms} from "../../apis/contract.form.service";

const state = {
    contract: {
        title: '',
        content: ''
    }
}

const getters = {
    GET_CONTRACT(state){
        return state.contract;
    }
}

const mutations = {
    SET_CONTRACT(state, contract){
        state.contract = contract;
    }
}

const actions = {
    updateContract({commit, dispatch}, { slug, content }){
        dispatch('showLoader')
        contractForms.updateContractForm(slug, content).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message, showSwal: true})
                return;
            }
            dispatch('success', {message: 'Completed!'});
        })
    },

    getContract({commit, dispatch}, { slug }){
        dispatch('showLoader')
        contractForms.getContractForm(slug).then(data => {
            dispatch('hideLoader')
            if(!data.status){
                dispatch('error', {message:data.message})
                return;
            }
            commit('SET_CONTRACT', data.extra);
            dispatch('setPageTitle', data.extra.name)
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}